/*
/*
@File: B-data React Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Button Area CSS
** - Animation Area CSS 

* -----------------------
        Home 01
--------------------------- *
** - Navbar Area CSS
** - Banner Area CSS
** - Service Section CSS
** - About Section CSS
** - Our Working process Section CSS
** - Recent Work Section CSS
** - Pricing Section CSS
** - Testimonial Section CSS
** - Blog Section CSS
** - Cta  Area CSS
** - Footer Section CSS
** - Copyright Area CSS
* -----------------------
        Home 02
--------------------------- *
** - Navbar Area CSS
** - Banner Area CSS
** - Faqs Area CSS
** - Testimonial Area CSS
* -----------------------
        Home 03
--------------------------- *
 ** - Navbar Area CSS
** - Banner Area CSS
** - Recent Work css
** - Team Section CSS
** - Downloads Area CSS
** - About Section CSS

* -----------------------
        Home 04
--------------------------- *
** - Navbar Area CSS
** - Banner Area CSS
** - Service Slider CSS
** - How It Work CSS
** - Our Core Features CSS
** - Our Work Process CSS
** - Frequently Questions CSS
** - Blog CSS
** - Footer CSS


* -----------------------
        Home 05
--------------------------- *
** - Navbar Area CSS
** - Banner Area CSS
** - About Area CSS
** - Our Services CSS
** - How It Works CSS
** - Frequently Questions CSS
** - Clients Testimonials CSS
** - Pricing Plan CSS
** - Our News CSS
** - Footer CSS

* -----------------------
       Other Page
--------------------------- *
**- Faqs Page
** - Shop Page
** - Shop-Details Page
** - Cart Page
** - CheckOut Page
** - Blog Page
**- Blog-details Page
** - Contact Page
** - Privacy-Police  Page
** - Login Page
** - Product-Details Page
** - Product page
**- Service Page
** - Service-Details Page
** - Shop-Details Page
** - About  Page
** - Preloder Css
** - Back-to-Top Button Css 

*

/*================================================
         Default CSS
=================================================*/

@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

html,
body {
	height: 100%;
}

body {
	padding: 0;
	margin: 0;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
}

img {
	max-width: 100%;
	height: auto;
}

.ptb-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pt-100 {
	padding-top: 100px;
}

a {
	text-decoration: none;
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
	outline: 0 !important;
	color: var(---heading-color);
}

a:hover {
	text-decoration: none;
	color: var(--main-theme-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Poppins', sans-serif;
}

h1 {
	font-size: 80px;
	font-weight: bold;
}

h2 {
	font-size: 30px;
	font-weight: 500;
	line-height: 48px;
}

h3 {
	font-size: 24px;
	font-weight: 500;
}

h4 {
	font-size: 22px;
	font-weight: 600;
}

h5 {
	font-size: 18px;
	font-weight: 400;
}

h6 {
	font-size: 14px;
	font-weight: 400;
}

p {
	font-size: 16px;
	line-height: 24px;
	color: var(--paragraph-color);
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
}

p:last-child {
	margin-bottom: 0;
}

ul {
	padding: 0;
	margin: 0;
}

ul li {
	list-style: none;
	padding: 0;
}

/* --Common Class Area start-- */

.box-shadow {
	-webkit-box-shadow: 3px 0px 12px 0px #9aa8ff30;
	box-shadow: 3px 0px 12px 0px #9aa8ff30;
}

.border-radius {
	border-top-left-radius: 35px;
	border-bottom-right-radius: 35px;
}

.bg-color {
	background-color: var(--dark-bg-color) !important;
}

.section-title-center h3 {
	color: var(--main-theme-color);
}

.left-side-title h3 {
	color: var(--main-theme-color);
}

/*================================================
     Default CSS
=================================================*/

/* --Common Class Area start-- */

/*--Common input style--*/

input[type='text'],
select,
input[type='password'] {
	min-height: 45px;
	background: #f7f7f7;
	border-color: #ddd;
}

.form-control:focus {
	background-color: transparent;
	border-color: transparent;
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: transparent;
}

input[type='text']:focus,
input[type='password']:focus,
select:focus {
	background-color: #fff;
	outline: none;
}

.img-zoom-hover img {
	-webkit-transform-origin: 65% 75%;
	-ms-transform-origin: 65% 75%;
	transform-origin: 65% 75%;
	-webkit-transition: -webkit-transform 1s, -webkit-filter 0.5s ease-out;
	transition: -webkit-transform 1s, -webkit-filter 0.5s ease-out;
	-o-transition: transform 1s, filter 0.5s ease-out;
	transition: transform 1s, filter 0.5s ease-out;
	transition: transform 1s, filter 0.5s ease-out, -webkit-transform 1s,
		-webkit-filter 0.5s ease-out;
}

.img-zoom-hover:hover img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

/*margin top & bottom*/

.my120 {
	margin: 120px 0;
}

.my110 {
	margin: 110px 0;
}

.my100 {
	margin: 100px 0;
}

.my90 {
	margin: 90px 0;
}

.my80 {
	margin: 80px 0;
}

.my70 {
	margin: 70px 0;
}

.my60 {
	margin: 60px 0;
}

.my50 {
	margin: 50px 0;
}

.my40 {
	margin: 40px 0;
}

.my30 {
	margin: 30px 0;
}

.my20 {
	margin: 20px 0;
}

.my10 {
	margin: 10px 0;
}

/*margin top*/

.mt120 {
	margin-top: 120px;
}

.mt110 {
	margin-top: 110px;
}

.mt100 {
	margin-top: 100px;
}

.mt90 {
	margin-top: 90px;
}

.mt80 {
	margin-top: 80px;
}

.mt70 {
	margin-top: 70px;
}

.mt60 {
	margin-top: 60px;
}

.mt50 {
	margin-top: 50px;
}

.mt40 {
	margin-top: 40px;
}

.mt30 {
	margin-top: 30px;
}

.mt20 {
	margin-top: 20px;
}

.mt10 {
	margin-top: 10px;
}

/*margin bottom*/

.mb120 {
	margin-bottom: 120px;
}

.mb110 {
	margin-bottom: 110px;
}

.mb100 {
	margin-bottom: 100px;
}

.mb90 {
	margin-bottom: 90px;
}

.mb80 {
	margin-bottom: 80px;
}

.mb70 {
	margin-bottom: 70px;
}

.mb60 {
	margin-bottom: 60px;
}

.mb50 {
	margin-bottom: 50px;
}

.mb40 {
	margin-bottom: 40px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb10 {
	margin-bottom: 10px;
}

/*padding top & bottom*/

.py120 {
	padding: 120px 0;
}

.py110 {
	padding: 110px 0;
}

.py100 {
	padding: 100px 0;
}

.py90 {
	padding: 90px 0;
}

.py80 {
	padding: 80px 0;
}

.py70 {
	padding: 70px 0;
}

.py60 {
	padding: 60px 0;
}

.py50 {
	padding: 50px 0;
}

.py40 {
	padding: 40px 0;
}

.py30 {
	padding: 30px 0;
}

.py20 {
	padding: 20px 0;
}

.py10 {
	padding: 10px 0;
}

/*padding top*/

.pt120 {
	padding-top: 120px;
}

.pt110 {
	padding-top: 110px;
}

.pt100 {
	padding-top: 100px;
}

.pt90 {
	padding-top: 90px;
}

.pt80 {
	padding-top: 80px;
}

.pt70 {
	padding-top: 70px;
}

.pt60 {
	padding-top: 60px;
}

.pt50 {
	padding-top: 50px;
}

.pt40 {
	padding-top: 40px;
}

.pt30 {
	padding-top: 30px;
}

.pt20 {
	padding-top: 20px;
}

.pt10 {
	padding-top: 10px;
}

/*padding bottom*/

.pb120 {
	padding-bottom: 120px;
}

.pb110 {
	padding-bottom: 110px;
}

.pb100 {
	padding-bottom: 100px;
}

.pb90 {
	padding-bottom: 90px;
}

.pb80 {
	padding-bottom: 80px;
}

.pb70 {
	padding-bottom: 70px;
}

.pb60 {
	padding-bottom: 60px;
}

.pb50 {
	padding-bottom: 50px;
}

.pb40 {
	padding-bottom: 40px;
}

.pb30 {
	padding-bottom: 30px;
}

.pb20 {
	padding-bottom: 20px;
}

.pb10 {
	padding-bottom: 10px;
}

.pl20 {
	padding-left: 20px;
}

/* --Button Area end-- */

/* --Button Area Start-- */

.btn-theme {
	background: var(--main-theme-color);
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
}

.btn-theme:hover {
	background: var(--heading-color);
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
}

.btn {
	color: #fff;
	position: relative;
	display: inline-block;
	padding: 0 35px;
	font-size: 18px;
	line-height: 45px;
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
	border-radius: 40px;
}

.btn-cta {
	background: #242f51;
	border-radius: 10px;
	padding: 0px 15px;
}

.btn-cta:hover {
	background: var(--main-theme-color);
	color: #fff;
	-webkit-box-shadow: 0px 0px 7px -3px #000;
	box-shadow: 0px 0px 7px -3px #000;
}

.btn-cta:focus {
	-webkit-box-shadow: 0px 0px 7px -3px #000 !important;
	box-shadow: 0px 0px 7px -3px #000 !important;
}

a.btn:hover {
	color: #fff;
	text-decoration: none;
}

.btn.focus,
.btn:focus {
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

a,
a:hover,
a:visited,
a:active,
a:focus {
	text-decoration: none;
	outline: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
}

section {
	position: relative;
}

/* --Button Area End-- */

/* ---Common-Banner--- */

#banner-inner-area {
	padding: 100px 0;
	/* background-image: url(../img/banner-inner.jpg); */
	background-color: #222436;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
}

#banner-inner-area:after {
	content: '';
	position: absolute;
	/* background: #222436; */
	background: #5f259f;
	height: 100%;
	width: 100%;
	top: 0;
}
.banner-details {
	position: relative;
	z-index: 99;
}

.banner-details ul li {
	display: inline-block;
	padding-right: 5px;
	color: #fff;
}
.banner-details ul li a {
	color: #fff;
}
.banner-details ul li a:hover {
	color: var(--main-theme-color);
}
.banner-details h1,
h2 {
	color: #fff;
}

.banner-details ul li span {
	padding-right: 5px;
}
/* --animation css area start-- */

.animation-img-one {
	-webkit-animation: img-animation-one 5s linear 0s infinite;
	animation: img-animation-one 5s linear 0s infinite;
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
}

@-webkit-keyframes img-animation-one {
	50% {
		-webkit-transform: translate(0, 10px);
		transform: translate(0, 10px);
	}
}

@keyframes img-animation-one {
	50% {
		-webkit-transform: translate(0, 10px);
		transform: translate(0, 10px);
	}
}

.animation-img-two {
	-webkit-animation: img-animation-two 5s linear 0s infinite;
	animation: img-animation-two 5s linear 0s infinite;
}

@-webkit-keyframes img-animation-two {
	50% {
		-webkit-transform: translate(10px, 0);
		transform: translate(10px, 0);
	}
}

@keyframes img-animation-two {
	50% {
		-webkit-transform: translate(10px, 0);
		transform: translate(10px, 0);
	}
}

/* --animation css area End-- */

/* ----------------------
      Home
----------------------------- */

/* --Navbar Area Start-- */
#bg-white {
	background-color: #fff;
	position: inherit;
}

.plamb-nav {
	background-color: transparent;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 0;
	padding-left: 0;
}

.plamb-nav .navbar {
	padding-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
}

.plamb-nav .navbar ul {
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0;
}

.plamb-nav .navbar .navbar-nav {
	margin: auto;
}

.plamb-nav .navbar .navbar-nav .nav-item {
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 0;
	padding-right: 0;
}

.plamb-nav .navbar .navbar-nav .nav-item a {
	font-size: 16px;
	font-weight: 600;
	color: var(--heading-color);
	text-transform: capitalize;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 15px;
	margin-right: 15px;
}

.plamb-nav .navbar .navbar-nav .nav-item a:hover,
.plamb-nav .navbar .navbar-nav .nav-item a:focus,
.plamb-nav .navbar .navbar-nav .nav-item a.active {
	color: var(--main-theme-color);
}

.plamb-nav .navbar .navbar-nav .nav-item a i {
	font-size: 15px;
	position: relative;
	top: 1px;
	margin-left: 5px;
}

.plamb-nav .navbar .navbar-nav .nav-item:last-child a {
	margin-right: 0;
}

.plamb-nav .navbar .navbar-nav .nav-item:hover a,
.plamb-nav .navbar .navbar-nav .nav-item:focus a,
.plamb-nav .navbar .navbar-nav .nav-item.active a {
	color: var(--main-theme-color);
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu {
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	background: #ffffff;
	position: absolute;
	border: none;
	top: 80px;
	left: 0;
	width: 230px;
	z-index: 99;
	display: block;
	opacity: 0;
	visibility: hidden;
	border-radius: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding-top: 20px;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 20px;
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
	padding: 0;
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
	text-transform: capitalize;
	padding: 8px 15px;
	padding-left: 0;
	margin: 0;
	position: relative;
	color: #393953;
	font-size: 15.5px;
	font-weight: 400;
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a::before {
	content: '';
	position: absolute;
	left: -5px;
	top: 50%;
	width: 0;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
	height: 1px;
	background-color: var(--main-theme-color);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
	color: var(--main-theme-color);
	padding-left: 26px;
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before,
.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::before,
.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
	width: 20px;
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
	left: -245px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
	color: #696997;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	color: var(--main-theme-color);
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu {
	left: -245px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a {
	color: #696997;
	text-transform: capitalize;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu {
	left: -245px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a {
	color: #696997;
	text-transform: capitalize;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu {
	left: 195px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a {
	color: #696997;
	text-transform: capitalize;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu {
	left: 195px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a {
	color: #696997;
	text-transform: capitalize;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu {
	left: 195px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a {
	color: #696997;
	text-transform: capitalize;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li.active
	a {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li:hover
	.dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li.active
	a {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li:hover
	.dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li.active
	a {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li:hover
	.dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li.active
	a {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li:hover
	.dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li.active
	a {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li:hover
	.dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
	color: #00b0ee;
}

.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li:hover
	.dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.plamb-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: 100%;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.plamb-nav .navbar .other-option {
	margin-left: 0;
	margin-top: 5px;
}

.plamb-nav .navbar .other-option .default-btn.seo-btn {
	padding: 10px 25px;
	font-size: 15px;
	background-color: transparent;
	border: 1px solid #00b0ee;
	color: #00b0ee;
}

.plamb-nav .navbar .other-option .default-btn.seo-btn:hover {
	background-color: #00b0ee;
	color: #ffffff;
}

.plamb-nav .black-logo {
	display: none;
}

.navbar-area {
	background-color: transparent;
	transition: 0.6s;
	position: relative;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
	box-shadow: 1px -13px 35px -18px #5b6fe6;
}

/* .navbar-area.is-sticky {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background: #ffffff !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
} */

.navbar-area.is-sticky .plamb-nav {
	padding-top: 15px;
}

.navbar-area.is-sticky .navbar-nav .nav-item a {
	color: #5b5b98;
}

.navbar-area.is-sticky .navbar-nav .nav-item a:hover,
.navbar-area.is-sticky .navbar-nav .nav-item a:focus,
.navbar-area.is-sticky .navbar-nav .nav-item a.active {
	color: #00b0ee;
}

.navbar-area.is-sticky .plamb-nav .navbar-brand .white-logo {
	display: none;
}

.navbar-area.is-sticky .plamb-nav .navbar-brand .black-logo {
	display: block;
}

/* Responsive Menu */
.mean-menu {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.plamb-responsive-nav {
	display: none;
}
@media only screen and (max-width: 991px) {
	.plamb-responsive-nav {
		display: block;
	}
	.navbar-nav {
		display: block;
	}
	.navbar-area {
		position: inherit;
	}
	.plamb-nav .navbar .navbar-nav {
		padding: 45px;
	}
	.plamb-nav .navbar .navbar-nav .nav-item a {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mean-menu {
		display: none;
		position: absolute;
		top: 63px;
		z-index: 999;
		background: #fff;
	}
	.navbar-area {
		padding: 0px 40px;
	}
	.responsive-button {
		position: absolute;
		right: 40px;
		top: 25px;
	}
	.responsive-button {
		font-size: 36px;
	}
}
/* Responsive Menu */
.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px; /* gutter size offset */
	width: auto;
	margin-top: 30px;
}
.my-masonry-grid_column {
	padding-left: 30px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	/* change div to reference your elements you put in <Masonry> */
	margin-bottom: 30px;
}

/* --Navbar Area End-- */

/* --Banner Area Start-- */

#banner-one {
	background-image: url('../img/home-one-banner/bg-banner.png');
	background-repeat: no-repeat;
	background-position: top right;
	padding: 160px 0;
}

.home-one-banner-content h1 {
	color: var(--main-theme-color);
}

.home-one-banner-content h2 {
	font-size: 40px;
	font-weight: 400;
	padding-top: 25px;
}

.home-one-banner-content p {
	padding-top: 20px;
	color: var(--banner-paragraph-color);
}

.banner-one-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.banner-one-btn a {
	margin-right: 45px;
}

.banner-one-btn a svg {
	font-size: 45px;
	margin-right: 15px;
}

.banner-video {
	font-size: 18px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.video-pop img {
	margin-top: 205px;
}

.player {
	position: fixed;
	z-index: 1;
	top: 45px;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;
}

.player__video {
	position: relative;
	top: 50%;
	left: 50%;
	height: 70%;
	width: auto;
	max-width: 75%;
	background-color: #fff;
	-webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.95);
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.95);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.js--show-video {
	opacity: 1;
}

.video-filler {
	display: block;
	width: 100%;
}

.video-close {
	position: absolute;
	z-index: 0;
	top: 0;
	right: -30px;
	padding: 5px 10px;
	border: none;
	outline: none;
	border-radius: 0 50% 50% 0;
	cursor: pointer;
	font-size: 24px;
	color: #000;
	background-color: #fff;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.75);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.75);
}

.video-iframe {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 10px solid #fff;
}

.banner-img img {
	width: 100%;
}

/* --Banner Area End-- */

/* --partner Area Start-- */

#partner {
	padding-bottom: 100px;
}

.slider-logo img {
	width: 80% !important;
}

.logo-area-heading h3 {
	color: var(--main-theme-color);
}

/* --partner Area End-- */

/* --Service Area Start-- */

#service {
	padding: 100px 0 70px 0;
}

.service-box {
	background: #fff;
	padding: 30px 30px;
	margin-bottom: 30px;
	position: relative;
	z-index: 9999;
}
.animation-translate {
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
}
.animation-translate:hover {
	transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
}
.service-box-inner img {
	-webkit-transition: all 0.5s ease-in;
	/* -o-transition: all 0.5s ease-in; */
	transition: all 0.5s ease-in;
}

.service-box:hover .service-box-inner img {
	/* -ms-transform: rotateY(360deg);
	-webkit-transform: rotateY(360deg);
	transform: rotateY(360deg); */
	color: #fff;
}

.service-box-inner h3 a {
	color: var(--heading-color);
}

.service-box:hover .service-box-inner h3 a,
.service-box:hover .service-box-inner a {
	color: var(--main-theme-color);
}

.service-box-inner p {
	padding-top: 10px;
}

/* --Service Area Start-- */

/* --About  Area Start-- */

.about-details ul li {
	padding-bottom: 20px;
}

.about-details ul li:last-child {
	padding: 0;
}

.about-details ul li img {
	padding-right: 10px;
}

#about-area-two {
	padding: 100px 0;
}

/* --Right Side About Start-- */

.right-side-about {
	width: 80%;
}

.right-area-about-list .media {
	padding-top: 30px;
}

.right-area-about-list .media img {
	-webkit-transition: all 0.5s ease-in;
	-o-transition: all 0.5s ease-in;
	transition: all 0.5s ease-in;
	width: 18%;
}

.right-area-about-list .media:hover img {
	-ms-transform: rotateY(360deg);
	-webkit-transform: rotateY(360deg);
	transform: rotateY(360deg);
	color: #fff;
}

/* --Right Side About End-- */

/* --About  Area Start-- */

/* --Our Working Process Area Start-- */

.work-process-item {
	padding: 30px 20px;
	position: relative;
	margin-top: 30px;
	background: #fff;
	z-index: 999;
}

.work-process-img {
	width: 160px;
	height: 160px;
	line-height: 160px;
	border-radius: 50%;
	margin: -70px auto 25px auto;
	background: #fff;
}

.work-process-img img {
	width: 60%;
}

.work-process-item:hover .work-process-details h3 a,
.work-process-item:hover .work-process-details a {
	color: var(--main-theme-color);
}

@keyframes left2right {
	50% {
		-ms-transform: translateY(10px);
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}
}

.work-process-item .work-process-img {
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-animation: left2right 3s linear 0s infinite;
	animation: left2right 3s linear 0s infinite;
}

/* --Our Working Process Area End-- */

/* --Recent work Area Start-- */

.grid {
	width: 100%;
	margin-top: 30px;
}

.work-post img {
	width: 100%;
}

.works-button {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}

.works-button button {
	background: white;
	border: navajowhite;
	height: 60px;
	width: 100%;
	margin-right: 10px;
	color: var(--heading-color);
	border-bottom-right-radius: 20px;
	border-top-left-radius: 20px;
	font-size: 18px;
	font-weight: 600;
	-webkit-transition: 0.4s all;
	-o-transition: 0.4s all;
	transition: 0.4s all;
}

.works-button .active {
	background-color: var(--main-theme-color);
	color: #fff;
}

.works-button button:hover {
	background-color: var(--main-theme-color);
	color: #fff;
}

.work-item {
	margin-bottom: 30px;
	position: relative;
	z-index: 9999;
}

.overlay-arae {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #5b6fe6ab;
	opacity: 0;
	-webkit-transition: 0.4s all;
	-o-transition: 0.4s all;
	transition: 0.4s all;
}

.overlay-arae:hover {
	opacity: 1;
}

.overlay-arae a {
	position: absolute;
	right: 30px;
	bottom: 14px;
}

.overlay-arae a i {
	color: #fff;
}
.overlay-arae a i svg {
	height: 50px;
	width: 55px;
}

.work-item img {
	width: 100%;
}

.works-button {
	padding: 25px 0;
}

/* --Recent work Area End-- */

/* --Pricing Area Start-- */

/** pricing-section **/

.pricing-section {
	position: relative;
	padding: 100px 0px 100px 0px;
	background: #fff;
}

.pricing-section .pattern-layer {
	position: absolute;
	bottom: -300px;
	right: 0px;
	width: 635px;
	height: 735px;
	background-repeat: no-repeat;
}

.tabs-box .tab {
	position: relative;
	display: none;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab {
	display: block;
}

.pricing-section .upper-box {
	position: relative;
	margin-bottom: 65px;
}

.pricing-section .upper-box .text {
	position: relative;
	max-width: 50%;
}

.pricing-section .tab-btn-box {
	position: relative;
	margin-top: 5px;
}

.pricing-section .tab-btn-box .tab-btns {
	position: relative;
	background: #f7faff;
	max-width: 285px;
	overflow: hidden;
	border-radius: 30px;
	text-align: center;
	margin: 0 auto;
	-webkit-box-shadow: 0 10px 20px 0px rgba(99, 119, 238, 0.2);
	box-shadow: 0 10px 20px 0px rgba(99, 119, 238, 0.2);
}

.pricing-section .tab-btn-box .tab-btns .nav-item {
	position: relative;
	float: left;
	width: 50%;
	background: transparent;
	border-radius: 30px;
	font-size: 17px;
	line-height: 15px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	color: #3b3b3b;
	cursor: pointer;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.pricing-section .tab-btn-box .tab-btns .nav-item a {
	padding: 20px 10px;
}
.pricing-section .tab-btn-box .tab-btns.active
/* .pricing-section .tab-btn-box .tab-btns li:hover  */ {
	color: #fff;
}

.pricing-section .tab-btn-box .tab-btns .active
/* .pricing-section .tab-btn-box .tab-btns li:hover  */ {
	background: #6377ee;
	color: #fff;
	-webkit-transition: 0.4s all;
	-o-transition: 0.4s all;
	transition: 0.4s all;
}

/* Pricing Table */

.pricing-table {
	-webkit-transition: 0.4s all;
	-o-transition: 0.4s all;
	transition: 0.4s all;
	position: relative;
	z-index: 999;
}

.pricing-table:hover {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

.pricing-table-header {
	background: var(--main-theme-color);
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#9185fb),
		to(#7667f8)
	);
	background-image: -o-linear-gradient(left, #9185fb, #7667f8);
	background-image: linear-gradient(to right, #9185fb, #7667f8);
	border-top-left-radius: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 10px 25px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0px 11px 27px -13px #353a58;
	box-shadow: 0px 11px 27px -13px #353a58;
}

.pricing-table-header h3 {
	margin: 0;
	color: #fff;
	font-weight: 300;
	padding-right: 15px;
}

.pricing-table-header h2 {
	margin: 0;
	background: #fff;
	font-size: 25px;
	height: 60px;
	line-height: 60px;
	width: 60px;
	text-align: center;
	border-radius: 50%;
	color: #000;
	font-weight: 700;
}

.pricing-table-content {
	padding: 30px 25px;
}

.pricing-table-content ul li {
	margin-bottom: 20px;
	font-size: 16px;
	color: var(--main-theme-color);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.pricing-table-content ul li:last-child {
	margin-bottom: 0px;
}

.pricing-table-content ul li i {
	font-size: 20px;
	color: var(--heading-color);
	padding-right: 10px;
}

.pricing-table-btn {
	padding-top: 35px;
}

.disabled {
	color: #777c87 !important;
	pointer-events: none !important;
}

.btn-pricing {
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	cursor: pointer;
	text-align: center;
	border: none;
	background-size: 300% 100%;
	border-radius: 50px;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-box-shadow: 0 0px 12px -3px rgb(91, 111, 230);
	box-shadow: 0 0px 12px -3px rgb(91, 111, 230);
}

.btn-pricing:hover {
	background-position: 100% 0;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.row.clearfix.parent
	.pricing-block:nth-child(1)
	.pricing-table
	.pricing-table-header {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#7667f8),
		to(#9185fb)
	);
	background-image: -o-linear-gradient(left, #7667f8, #9185fb);
	background-image: linear-gradient(to right, #7667f8, #9185fb);
}

.parent
	.pricing-block:nth-child(1)
	.pricing-table
	.pricing-table-content
	.pricing-table-btn
	.btn-pricing {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#7667f8),
		color-stop(51%, #5fc3e4),
		to(#9185fb)
	);
	background-image: -o-linear-gradient(
		left,
		#7667f8 0%,
		#5fc3e4 51%,
		#9185fb 100%
	);
	background-image: linear-gradient(
		to right,
		#7667f8 0%,
		#5fc3e4 51%,
		#9185fb 100%
	);
}

.row.clearfix.parent
	.pricing-block:nth-child(2)
	.pricing-table
	.pricing-table-header {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#fe545f),
		to(#ff717a)
	);
	background-image: -o-linear-gradient(left, #fe545f, #ff717a);
	background-image: linear-gradient(to right, #fe545f, #ff717a);
}

.parent
	.pricing-block:nth-child(2)
	.pricing-table
	.pricing-table-content
	.pricing-table-btn
	.btn-pricing {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#fe545f),
		color-stop(51%, #ff707959),
		to(#ff717a)
	);
	background-image: -o-linear-gradient(
		left,
		#fe545f 0%,
		#ff707959 51%,
		#ff717a 100%
	);
	background-image: linear-gradient(
		to right,
		#fe545f 0%,
		#ff707959 51%,
		#ff717a 100%
	);
}

.row.clearfix.parent
	.pricing-block:nth-child(3)
	.pricing-table
	.pricing-table-header {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#d260f6),
		to(#e38aff)
	);
	background-image: -o-linear-gradient(left, #d260f6, #e38aff);
	background-image: linear-gradient(to right, #d260f6, #e38aff);
}

.parent
	.pricing-block:nth-child(3)
	.pricing-table
	.pricing-table-content
	.pricing-table-btn
	.btn-pricing {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#d260f6),
		color-stop(51%, #e389ffbf),
		to(#e38aff)
	);
	background-image: -o-linear-gradient(
		left,
		#d260f6 0%,
		#e389ffbf 51%,
		#e38aff 100%
	);
	background-image: linear-gradient(
		to right,
		#d260f6 0%,
		#e389ffbf 51%,
		#e38aff 100%
	);
}

/* Pricing Table */

/* --Pricing Area End-- */

/* --Testimonial Area Start-- */

#one-testimonial .testimonial-box {
	background-color: #fff;
	padding: 50px 45px 40px 45px;
	margin: 0 20px 30px 30px;
	position: relative;
	z-index: 999;
}
.bg-colo-white {
	background-color: #fff !important;
}
#one-testimonial .testimonial-comment p {
	color: #000;
	font-style: italic;
	position: relative;
}

#one-testimonial .testimonial-author {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 35px;
}

#one-testimonial .testimonial-author-img {
	height: 55px;
	width: 55px;
	border-radius: 50%;
	border: 2px solid var(--main-theme-color);
}

#one-testimonial .testimonial-author-img img {
	border-radius: 50%;
}

#one-testimonial .testimonial-name {
	padding-left: 20px;
}

#one-testimonial .testimonial-name h3 {
	margin: 0;
	font-weight: 500;
}

#one-testimonial .owl-nav .owl-prev,
#one-testimonial .owl-nav .owl-next {
	color: var(--heading-color);
	font-size: 30px;
	background: transparent;
	line-height: 0;
}

#one-testimonial .owl-nav .owl-prev:hover,
#one-testimonial .owl-nav .owl-next:hover {
	color: var(--main-theme-color);
}

button:focus {
	outline: none;
	outline: none;
}

#one-testimonial .testimonial-comment {
	position: relative;
}

#one-testimonial .testimonial-comment i {
	font-size: 40px;
	color: var(--main-theme-color);
	opacity: 0.6;
	position: absolute;
	left: -15px;
	top: -15px;
}

/* --Testimonial Area End-- */

/* --Blog Area Start-- */

.blog-item {
	position: relative;
	border-bottom-right-radius: 35px;
	border-top-left-radius: 35px;
	-webkit-box-shadow: 0px 0px 8px -4px var(--main-theme-color);
	box-shadow: 0px 0px 8px -4px var(--main-theme-color);
	-webkit-transition: 0.4s all;
	-o-transition: 0.4s all;
	transition: 0.4s all;
	z-index: 9999;
}

.blog-item:hover {
	-webkit-box-shadow: 0px 10px 41px -22px #5969c3;
	box-shadow: 0px 10px 41px -22px #5969c3;
}

.date-time {
	width: 20%;
	text-align: center;
	padding: 7px 4px;
	-webkit-box-shadow: 0px 6px 4px -8px #000;
	box-shadow: 0px 6px 4px -8px #000;
	position: absolute;
	top: -40px;
	background: #fff;
}

.date-time h3 {
	margin: 0;
}

.blog-img a img {
	border-top-left-radius: 35px;
	width: 100%;
}

.blog-details {
	padding: 40px 15px 20px 15px;
	position: relative;
}

.author-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.author-img a img {
	padding-right: 20px;
}

/* --Blog Area Start-- */

/* --Cta Area Start-- */

#cta-area {
	background-color: var(--main-theme-color);
}

#cta-area .form-control {
	height: 70px;
	padding: 20px;
	font-size: 16px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.pl40 {
	padding-left: 40px;
}

.pr80 {
	padding-right: 80px;
}

.white-color {
	color: #fff;
}

.news-letter-text {
	color: #fff;
}

/* --Cta Area End-- */

/* --Footer Area Statrt-- */
#footer {
	position: relative;
}
.footer-contact-area li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
}

.footer-contact-area ul li:last-child {
	margin: 0;
}

.footer-contact-area ul li i {
	font-size: 30px;
	display: block;
	padding-right: 15px;
	color: var(--main-theme-color);
}

.footer-heading h5 {
	text-transform: uppercase;
	font-weight: 500;
	position: relative;
}

.footer-heading h5:before {
	content: '';
	position: absolute;
	height: 2px;
	width: 40px;
	background: var(--main-theme-color);
	bottom: -10px;
}

.footer-list ul li {
	margin-bottom: 20px;
}

.footer-list ul li:last-child {
	margin: 0;
}

.footer-bottom-img {
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: -1;
	opacity: 0.05;
}

/* --Footer Area End-- */

/* --Copyright Area Start-- */

.copy-right {
	padding: 20px 0;
	background: white;
	-webkit-box-shadow: 0px 0px 26px -20px #000;
	box-shadow: 0px 0px 26px -20px #000;
}

.copy-icon ul li {
	display: inline-flex;
}
.copy-icon ul li a:hover {
	border: 2px solid transparent;
}
.copy-icon ul li a {
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	border: 2px solid var(--main-theme-color);
	font-size: 18px;
	margin-left: 10px;
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
	color: var(--heading-color);
	display: block;
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.copy-icon ul li a:hover svg {
	fill: #fff;
	position: relative;
	z-index: 99;
}
.copy-icon ul li a .icon {
	position: relative;
	color: #262626;
	transition: 0.5s;
	z-index: 3;
}
.copy-icon ul li a:hover .icon {
	color: #fff;
	transform: rotateY(360deg);
}
.copy-icon ul li a:before {
	content: '';
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	background: #f00;
	transition: 0.5s;
	z-index: 2;
}
.copy-icon ul li a:hover:before {
	top: 0;
}

.copy-icon ul li:nth-child(1) a:before {
	background: #3b5999;
}

.copy-icon ul li:nth-child(2) a:before {
	background: #55acee;
}

.copy-icon ul li:nth-child(3) a:before {
	background: #0077b5;
}

/* .copy-icon a i:hover {
    background-color: var(--main-theme-color);
    color: #fff;
} */

/* --Copyright Area End-- */

/* -----------------------------------
  Home Page 02
---------------------------------------*/

/* banner Area css start */

#banner {
	background: url(../img/home-two-banner/bg-banner.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 120px 0 0 0;
}

.banner-two-text {
	text-align: center;
}

.banner-two-text h1 {
	font-size: 48px;
	color: #fff;
	font-weight: 400;
}

.banner-two-text p {
	font-size: 18px;
	color: #fff;
	width: 52%;
	margin: 0 auto;
	font-weight: 300;
	padding-top: 15px;
}

.banner-input {
	width: 45%;
	margin: 0 auto;
	padding-top: 50px;
}

#banner .input-group > .custom-select:not(:last-child),
#banner .input-group > .form-control:not(:last-child) {
	border-radius: 30px;
}

.banner-input .form-control {
	border-radius: 30px;
	height: 47px;
	position: relative;
}

.input-group-btn button {
	position: absolute;
	right: 0;
	z-index: 99999;
}

.bann-img {
	margin-bottom: -58px;
	padding-top: 65px;
}
#about .tab-content ul li img {
	padding-right: 20px;
}

/* banner Area css end */

/* feature Area css Start */

#data-science {
	background-image: url('../img/home-2.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.feature-area-bg {
	padding: 100px 30px;
	background: #fff;
}

#data-science .single-feature .media img {
	padding-right: 15px;
}

.media-body h3 a:hover {
	color: var(--main-theme-color);
}

/* --feature-row Area-- */

.feature-row {
	padding: 100px 0;
}

.data-check-arae li {
	margin-bottom: 15px;
	font-weight: 500;
}

.data-check-arae li img {
	padding-right: 15px;
}

.data-check-arae {
	padding-bottom: 30px;
}

#data-science .feature-row .row:nth-child(even) {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.feature-row .row {
	padding-bottom: 50px;
}

.feature-row .row:last-child {
	padding: 0;
}

/* feature Area css end */

/* Faq Area  CSS Start  */

#accordion .card {
	border: 0;
	border-radius: 0;
}

#accordion .card-header {
	padding: 25px 20px;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	background: #fff;
}

.accordion-wrapper .card .card-header a {
	display: block;
	font-size: 16px;
	line-height: 26px;
	font-weight: 600;
	cursor: pointer;
	position: relative;
	color: var(--theme-color);
	padding-right: 40px;
}

.faq-right-side {
	margin-left: 30px;
}

#accordion .card {
	padding-bottom: 30px;
}

#faqs-two #accordion .card:last-child {
	padding-bottom: 0px;
}

/*Testimonial Area css Start */

.home-two-pricing {
	padding-bottom: 250px;
}

.testmonial-veido {
	position: relative;
	margin-top: -250px;
}

.testmonial-veido img {
	position: relative;
}

.veido-play {
	background: #00000030;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.veido-play a {
	background: #fff;
	padding: 10px 10px;
	line-height: 20%;
	border-radius: 50%;
	cursor: pointer;
}

.veido-play a i {
	font-size: 50px;
}

.clients-review {
	overflow: initial;
	padding-bottom: 150px;
}

.testimonial-thumbnail {
	margin-top: -250px;
	z-index: 999;
	position: relative;
	margin-bottom: 170px;
}

.designation span {
	font-weight: 700;
	font-size: 18px;
}

.designation {
	color: var(--main-theme-color);
	font-size: 16px;
	font-weight: 300;
}

#testimonial-area .owl-nav button {
	position: absolute;
	right: 0;
	bottom: 0;
	color: var(--main-theme-color) !important;
	font-size: 25px !important;
}

#testimonial-area .owl-nav button:hover {
	color: #9696ff !important;
}

#testimonial-area .owl-nav button.owl-prev {
	position: absolute;
	right: 40px;
	color: #9696ff !important;
}

.play-icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	left: 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;
}

.clients-review-slides {
	margin-top: 50px;
}

#testimonial-area .owl-dots button {
	display: inline-block;
	width: 80px;
	height: 110px;
	position: relative;
}

#testimonial-area .owl-dots {
	display: inline-block;
	width: 100%;
	height: auto;
	position: absolute;
	bottom: 0px;
	z-index: 3 !important;
	right: 263px;
	left: auto;
	outline: none;
}

#testimonial-area .owl-dots button:after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-size: cover;
	background-position: center;
	background-color: aliceblue;
	background-repeat: no-repeat;
}

#testimonial-area .owl-dots button:nth-child(1):after {
	background-image: url(../img/client/clients_img_1.jpg);
}

#testimonial-area .owl-dots button:nth-child(2):after {
	background-image: url(../img/client/clients_img_2.jpg);
}

#testimonial-area .owl-dots button:nth-child(3):after {
	background-image: url(../img/client/clients_img_3.jpg);
}

#testimonial-area .owl-dots button:nth-child(4):after {
	background-image: url(../img/client/clients_img_4.jpg);
}

#testimonial-area .owl-dots button {
	width: 70px;
	height: 90px;
	background-color: gray !important;
	margin: 0 3px;
	position: relative;
	z-index: 9;
	border: 2px solid #5567ff !important;
}

#testimonial-area .owl-dots .active {
	position: absolute;
	left: -220px;
	z-index: 1;
	width: 300px;
	height: 360px;
	bottom: 0;
	cursor: text;
}
.review-slide-item {
	padding-top: 30px;
}
.testimonial_padding {
	margin-top: 150px;
}

/* Testimonial Area css End */

/* Faqs Area css End */

#faqs-two .faqs-items {
	margin-top: 50px;
}

/* Faqs Area css End */

/* -----------------------------------
  Home Page 03
---------------------------------------*/

/* --Navber Area-- */

#home-thre-nav .plamb-nav .navbar .navbar-nav .nav-item a {
	color: #ffffff;
}

#home-thre-nav .plamb-nav .navbar .navbar-nav .nav-item a:hover {
	color: #70befa;
}

#home-thre-nav .plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu {
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(91, 111, 230, 0.11);
	background: #0000009c;
}

#home-thre-nav
	.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	a::before {
	background-color: #ffff;
}

.navbar-area-three.is-sticky-three {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999999;
	-webkit-box-shadow: 0 2px 28px 0 rgba(27, 16, 16, 0.06);
	box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
	background: #000 !important;
	-webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.navbar-area-three.is-sticky-three
	#home-thre-nav
	.plamb-nav
	.navbar
	.navbar-nav
	.nav-item
	a:hover {
	color: #fff;
}

.navbar-area-three.is-sticky-three .plamb-nav {
	padding-top: 15px;
}

.navbar-area-three {
	background-color: transparent;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
	position: absolute;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
}

/* --Banner Area-- */

#banner-three {
	background-image: url(../img/home-three-banner/banner-shap.png);
	background-repeat: no-repeat;
	background-position: top left;
	padding: 175px 0 225px 0;
	height: 100%;
}

.home-three-banner-content {
	padding-left: 380px;
}

.home-three-banner-content h1 {
	color: #fff;
}

.home-three-banner-content h2 {
	color: #fff;
	font-size: 40px;
	font-weight: 300;
	padding-top: 15px;
}

.home-three-banner-content p {
	color: #fff;
	padding-top: 15px;
}

.banner-three-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.banner-three-btn a {
	margin-right: 45px;
	color: #fff;
}

.banner-three-btn a:hover {
	color: var(--main-theme-color);
}

.banner-slider img {
	width: 50% !important;
}

/* --Recent Work-- */

#recent-work-three {
	background-image: url(../img/recent-bg.png);
	background-repeat: no-repeat;
	background-position: right;
}

.recent-img-three {
	position: relative;
	padding-left: 200px;
}

.recent-img-three h3 {
	height: 45px;
	width: 45px;
	background: var(--main-theme-color);
	text-align: center;
	line-height: 45px;
	border-radius: 50%;
	color: #fff;
}

.recent-img-three h3:before,
.recent-img-three h3:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 999;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	border: 1px solid var(--main-theme-color);
}

@-webkit-keyframes ripple {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	25% {
		-webkit-transform: scale(1.75);
		transform: scale(2);
	}

	50% {
		-webkit-transform: scale(3);
		transform: scale(3);
	}

	75% {
		-webkit-transform: scale(3.75);
		transform: scale(3.75);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(4);
		transform: scale(4);
		opacity: 0;
	}
}

@keyframes ripple {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	25% {
		-webkit-transform: scale(1.75);
		transform: scale(1.75);
	}

	50% {
		-webkit-transform: scale(3);
		transform: scale(3);
	}

	75% {
		-webkit-transform: scale(3.75);
		transform: scale(3.75);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(4);
		transform: scale(4);
		opacity: 0;
	}
}

.recent-img-three img {
	position: relative;
	z-index: 9999;
}

.recent-img-three h3:before {
	-webkit-animation: ripple 4s linear infinite;
	animation: ripple 4s linear infinite;
}

.recent-img-three h3:after {
	-webkit-animation: ripple 4s linear 2s infinite;
	animation: ripple 4s linear 2s infinite;
}

.pulse-arae {
	position: absolute;
	right: 35%;
	top: 25px;
}

.recent-work-slider-area {
	padding-right: 370px;
}

.recent-text-area {
	padding-top: 20px;
}

.recent-slider-text a {
	margin-top: 15px;
}

#recent-work-three .owl-nav {
	position: absolute;
	right: 23%;
	bottom: 60px;
}

#recent-work-three .owl-theme .owl-nav [class*='owl-']:hover {
	background: transparent;
	color: var(--main-theme-color);
	text-decoration: none;
}

.left-side-recent h3 {
	line-height: 40px;
}

#recent-work-three .owl-item {
	padding: 45px 0;
}

/* --Team Area-- */

.team-img img {
	width: 204px;
	height: 204px;
	border-radius: 50%;
	-webkit-box-shadow: 0px 0px 19px -9px var(--main-theme-color);
	box-shadow: 0px 0px 19px -9px var(--main-theme-color);
}

.team-img {
	padding-bottom: 15px;
}

.team-text h3 {
	margin: 0;
}

.team-img {
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	width: 204px;
	height: 204px;
	margin: auto;
}

.team-img img {
	width: 204px;
	height: 204px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center center;
	object-position: center center;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.team-img:hover img {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

.social {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #5b6fe6a6;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.full-width {
	width: 100%;
}

.valign {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.social .icon {
	margin: 0 5px;
	color: #fff;
	font-size: 22px;
}

.team-img:hover .social {
	opacity: 1;
	visibility: visible;
}

.team-text {
	padding-top: 20px;
}

/*-- Downloads Area-- */

.downloads-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	display: flex;
	padding-top: 10px;
}

.downloads-btn a {
	width: 40%;
	padding-right: 25px;
}

.downloads-btn a img {
	width: 100%;
}

.downloads-phone-img {
	position: relative;
	text-align: center;
}

.phone-animation {
	position: absolute;
	left: 110px;
	width: 80%;
	-webkit-transition: 0.4s all;
	-o-transition: 0.4s all;
	transition: 0.4s all;
	-webkit-animation: left1right 2s linear 1s infinite;
	animation: left1right 2s linear 1s infinite;
}

@keyframes left1right {
	50% {
		-ms-transform: translateY(10px);
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}
}
@-webkit-keyframes left1right {
	50% {
		-ms-transform: translateY(10px);
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}
}

.phone-bg {
	width: 78%;
	padding-top: 63px;
	padding-left: 78px;
}

/* About Area CSS start */

.tabs-about-button {
	padding-top: 15px;
}

.tabs-about-button .nav-tabs .nav-link {
	font-size: 24px;
	font-weight: 500;
}

.tabs-about-button .nav-tabs .nav-item.show .nav-link,
.tabs-about-button .nav-tabs .nav-link.active {
	background-color: transparent;
	border-color: transparent;
	border-bottom: 2px solid var(--main-theme-color);
}

.tabs-about-button .nav-tabs .nav-link:focus,
.tabs-about-button .nav-tabs .nav-link:hover {
	border-color: transparent;
	border-bottom: 2px solid var(--main-theme-color);
	color: var(--main-theme-color);
}

.tabs-about-button .nav-tabs {
	border-bottom: 1px solid #dee2e6;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

#about .tab-content {
	padding-top: 30px;
}

.tab-content ul {
	padding-top: 20px;
}

.tab-content ul li {
	color: var(--paragraph-color);
	margin-bottom: 15px;
}
#chb1,
#chb2 {
	margin-right: 10px;
}
.reset-Password {
	padding-bottom: 30px;
}
.padding-areas {
	padding-top: 100px;
}

/* About Area CSS End */

/* --------------------------
     Home Page 4
-----------------------------*/
/* Banner Area Start */
#banner-four {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.hero-shape {
	position: absolute;
	left: auto;
	top: 7%;
	right: -42%;
	bottom: auto;
	width: 85%;
	height: 111%;
	border-radius: 100px;
	background-color: #162e66;
	-webkit-transform: rotate(-35deg);
	-ms-transform: rotate(-35deg);
	transform: rotate(-62deg);
}
.bg-shape {
	position: absolute;
	height: 180%;
	width: 100%;
	display: block;
	border-radius: 145px;
	background: #4777f4 none repeat scroll 0 0;
	bottom: 0;
	right: 0;
	-webkit-transform: translate(35%, -30%) rotate(-35deg);
	transform: translate(35%, -30%) rotate(-35deg);
	z-index: 0;
	opacity: 0.3;
}
/* Banner Area End */

/* Banner Bottom Area Start */
.banner_bootom_box {
	display: block;
	overflow: hidden;
	position: relative;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background: #fff;
	box-shadow: 3px 0px 12px 0px #5b6fe633;
	padding: 40px 10px;
	position: relative;
	z-index: 999;
	margin: 20px 12px;
}
.banner_bootom_box:before,
.banner_bootom_box:after {
	content: '';
	height: 100%;
	right: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
	-webkit-border-radius: inherit;
	border-radius: inherit;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	z-index: -1;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
}
.banner_bootom_box:before {
	top: 80%;
	opacity: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-filter: brightness(40%);
	filter: brightness(40%);
	background-color: #5b6fe6;
}
.banner_bootom_box:after {
	z-index: 1;
	top: 100%;
	background-image: linear-gradient(
		75deg,
		rgba(93, 78, 230, 0.85) 10%,
		rgba(19, 13, 65, 0.85)
	);
}

.banner_bootom_box:hover:before {
	top: 0;
	opacity: 1;
	-webkit-transition: all 0.17s ease-out 0.18s;
	transition: all 0.17s ease-out 0.18s;
}
.banner_bootom_text {
	padding-top: 25px;
}
.banner_bootom_text p {
	padding-top: 10px;
	-webkit-transition: all 0.17s ease-out 0.18s;
	transition: all 0.17s ease-out 0.18s;
}
.banner_bootom_text h3 {
	-webkit-transition: all 0.17s ease-out 0.18s;
	transition: all 0.17s ease-out 0.18s;
	color: var(--main-theme-color);
}

.banner_bootom_box:hover .banner_bootom_text p,
.banner_bootom_box:hover .banner_bootom_text h3 {
	color: #fff;
}
.banner_bootom_icon {
	background: #fff;
	width: 85px;
	margin: 0 auto;
	box-shadow: 3px 0px 12px 0px #5b6fe633;
	height: 85px;
	line-height: 85px;
	border-radius: 50%;
	-webkit-transition: all 0.17s ease-out 0.18s;
	transition: all 0.17s ease-out 0.18s;
	display: flex;
	align-items: center;
	justify-content: center;
}
.banner_bootom_box:hover .banner_bootom_icon {
	transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
}
.promo-hover-bg-1:before {
	background-image: url('../img/about-page.png');
}
.banner_bootom_icon img {
	width: auto !important;
	display: inherit !important;
}

#banner_bottom .owl-theme .owl-nav [class*='owl-']:hover {
	background: transparent;
	color: var(--main-theme-color);
}
.banner_bootom_text a {
	color: var(--main-theme-color);
	display: flex;
	align-items: center;
	justify-content: center;
}
.banner_bootom_text a svg {
	margin-left: 10px;
}
.banner_bootom_box:hover .banner_bootom_text a {
	color: #fff;
}

/* About Area Start */
.home4-about-list {
	padding-top: 10px;
}
.home4-about-list ul li {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
}
.home4-about-list ul li:hover {
	transform: translateX(-10px);
	-webkit-transform: translateX(-10px);
	-moz-transform: translateX(-10px);
	-ms-transform: translateX(-10px);
	-o-transform: translateX(-10px);
}
.home4-about-list ul li img {
	width: 30px;
	height: 30px;
	padding-right: 10px;
}
.who-we-contant a {
	margin-top: 30px;
}
/* About Area End */

/* How-It-Works-Start */

.how-it-wors-title p {
	padding-top: 10px;
}
.how-it-single-item {
	position: relative;
	margin-bottom: 40px;
	padding-left: 100px;
}
.how-it-single-item:before {
	position: absolute;
	content: '';
	width: 2px;
	height: 100%;
	left: 32px;
	top: 60px;
	background: var(--main-theme-color);
}
.how-it-single-item:last-child {
	margin: 0;
}
.how-it-inner-boxed {
	padding-top: 50px;
}
.how-it-single-item:last-child::before,
.how-it-single-item:last-child::after {
	display: none;
}
.how-it-count-box {
	position: absolute;
	left: 0;
	top: 12px;
	font-size: 30px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	background: #fff;
	width: 66px;
	height: 66px;
	line-height: 66px;
	text-align: center;
	border-radius: 50%;
	z-index: 1;
	box-shadow: 0 0 0 7px #edf1fe;
	transition: all 500ms ease;
}
.how-it-single-item:hover .how-it-count-box {
	color: #fff;
	background-color: var(--main-theme-color);
}
.how-it-single-item:hover .how-items-texed h3 a {
	color: var(--main-theme-color);
}
.how-it-count-box:before {
	position: absolute;
	content: '';
	width: 80px;
	height: 80px;
	left: -7px;
	top: -7px;
	border-radius: 50%;
	z-index: -1;
	box-shadow: 0 30px 50px 0 rgba(99, 119, 238, 0.2);
}
.how-items-texed h3 {
	margin-bottom: 15px;
}
/* How-It-Works-End */

/* Core Area Start */
.section-center-two h2 {
	padding-bottom: 10px;
	margin: 0;
}
.core-area-item {
	box-shadow: 0px 0px 29px 6px rgba(154, 161, 171, 0.18);
	-webkit-box-shadow: 0px 0px 29px 6px rgba(154, 161, 171, 0.18);
	padding: 30px 20px 20px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	display: flex;
	margin-bottom: 30px;
	transition: 0.4s all;
	-webkit-transition: 0.4s all;
	-moz-transition: 0.4s all;
	-ms-transition: 0.4s all;
	-o-transition: 0.4s all;
}
.core-area-item:hover {
	transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
}
.core-area-item:last-child {
	margin-bottom: 0;
}
.core-items-text {
	padding-left: 30px;
}
.core-items-text h3 {
	font-size: 20px;
}
/* Core Area End */

/* Work Process Strat */
.work-process-wrappers {
	margin: 20px -15px -60px;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	-moz-align-items: flex-start;
	-ms-align-items: flex-start;
	align-items: flex-start;
}
.single-items-process {
	position: relative;
	margin: 0 15px 60px;
	width: calc(25% - 30px);
}
.work-process-icons {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 70px;
	line-height: 70px;
	background: #1351a8;
	text-align: center;
}
.work-process-icons i {
	color: #fff;
}
.process-step {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	right: -20px;
	top: -20px;
	font-size: 20px;
	font-weight: 700;
	background-color: #fff;
	color: #1351a8;
}
.work-process-divider {
	position: absolute;
	left: auto;
	top: 40px;
	width: calc(100% - 130px);
	height: 1px;
	background-image: linear-gradient(
		90deg,
		#73778c,
		#73778c 40%,
		transparent 40%,
		transparent 100%
	);
	background-size: 12px 1px;
}
.single-items-process:hover .work-process-divider {
	animation: arrow 1s linear 0s infinite;
	-webkit-animation: arrow 1s linear 0s infinite;
}
@keyframes arrow {
	50% {
		transform: translateX(10px);
		-webkit-transform: translateX(10px);
		-moz-transformtranslatex: (10px);
		-ms-transform: translateX(10px);
		-o-transform: translateX(10px);
	}
}
.work-process-text {
	padding-top: 30px;
}
/* Work Process End */

/* Service Area Start */
.service-single-items {
	border-radius: 10px;
	background-color: #fff;
	-webkit-box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.11);
	box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
	border: none;
	padding: 40px 20px;
	margin-bottom: 30px;
	text-align: center;
	z-index: 9999;
	position: relative;
	overflow: hidden;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
}
.service-single-items:hover {
	transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
}
.service-single-items::before {
	content: '';
	height: 100%;
	right: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
	-webkit-border-radius: inherit;
	border-radius: inherit;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	z-index: -1;
}
.service-single-items::before {
	top: 80%;
	opacity: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #5b6fe6;
}
.service-single-items:hover:before {
	top: 0;
	opacity: 1;
	-webkit-transition: all 0.17s ease-out 0.18s;
	transition: all 0.17s ease-out 0.18s;
}
.service-single-items img {
	fill: '#fff';
}
.service-single-items h4 a {
	padding-top: 30px;
	padding-bottom: 10px;
	padding-bottom: 10px;
	font-size: 18px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	display: block;
}
.service-single-items p,
.service-single-items img {
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
}
.service-single-items:hover h4 a,
.service-single-items:hover p {
	color: #fff;
}

.service-single-items:hover img {
	filter: invert(1);
}
/* Service Area End */

/* blog Area Strat */
.blog-items-home4 {
	background: #ffffff;
	border-radius: 10px;
}
.blog-imgs-home4 {
	position: relative;
	z-index: 1;
}
.blog-imgs-home4 a {
	display: block;
}
.blog-imgs-home4 a img {
	border-radius: 10px 10px 0 0;
}
.dats-area-home4 {
	position: absolute;
	left: 30px;
	bottom: -30px;
	text-align: center;
	background: #ffffff;
	padding: 15px 30px;
	text-transform: capitalize;
	font-weight: 600;
	line-height: 27px;
	box-shadow: 0 0 10px #cccccc;
	border-radius: 5px;
}
.blog-home4-info {
	padding: 30px;
	padding-top: 55px;
	position: relative;
	z-index: 999;
}
.meta-area {
	margin-bottom: 10px;
}
.meta-area ul li:first-child {
	margin-right: 0;
}
.meta-area ul li {
	display: inline-block;
	text-transform: uppercase;
	font-size: 13px;
	margin-right: 7px;
	position: relative;
	z-index: 1;
	margin-bottom: 5px;
}
.meta-area ul li a {
	display: inline-block;
	font-weight: 500;
	color: #666666;
	font-size: 14px;
}
.blog-home4-info h4 {
	line-height: 30px;
	margin-bottom: 20px;
	font-weight: 500;
}
/* blog Area End */

/* ------------------------
        Home Page 5
----------------------------*/
#banner-five {
	background-color: transparent;
	background-image: linear-gradient(60deg, #5f259f 0%, #9b40ef 100%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 76vh;
	position: relative;
	overflow: hidden;
}

.color-white {
	color: #fff !important;
}
/* how it works start */
.how-it-works-items {
	padding-bottom: 95px;
}
.works-items-wrappers .how-it-works-items:last-child {
	padding-bottom: 0;
}
.works-items-wrappers .how-it-works-items:nth-child(2) .how-text-five {
	padding-left: 0;
	padding-right: 25px;
	text-align: right;
}
.text-icons-how-work {
	display: flex;
	align-items: center;
	padding-right: 40px;
}
.works-items-wrappers .how-it-works-items:nth-child(1) .how-icons-five svg {
	fill: #36bafe;
}
.works-items-wrappers .how-it-works-items:nth-child(2) .how-icons-five svg {
	fill: #ff3838;
}
.works-items-wrappers .how-it-works-items:nth-child(3) .how-icons-five svg {
	fill: #ff930e;
}
.works-items-wrappers
	.how-it-works-items:nth-child(1)
	.text-icons-how-work:hover
	.how-icons-five {
	background-color: #36bafe;
}
.works-items-wrappers
	.how-it-works-items:nth-child(2)
	.text-icons-how-work:hover
	.how-icons-five {
	background-color: #ff3838;
}
.works-items-wrappers
	.how-it-works-items:nth-child(3)
	.text-icons-how-work:hover
	.how-icons-five {
	background-color: #ff930e;
}
.works-items-wrappers
	.how-it-works-items:nth-child(1)
	.text-icons-how-work:hover
	.how-icons-five
	svg {
	fill: #fff;
}
.works-items-wrappers
	.how-it-works-items:nth-child(2)
	.text-icons-how-work:hover
	.how-icons-five
	svg {
	fill: #fff;
}
.works-items-wrappers
	.how-it-works-items:nth-child(3)
	.text-icons-how-work:hover
	.how-icons-five
	svg {
	fill: #fff;
}
.how-icons-five {
	width: 130px;
	height: 130px;
	line-height: 130px;
	text-align: center;
	border-radius: 50%;
	display: inline-table;
	z-index: 9999;
	background: #fff;
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
	position: relative;
}
.how-icons-five svg {
	height: 70px;
	width: 70px;
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.bottom-area-svgs svg {
	width: 320px;
	height: 120px;
}
.how-text-five {
	padding-left: 25px;
}
.bottom-area-svgs {
	position: relative;
}
.bottom-area-svgs svg {
	position: absolute;
	left: 90px;
	top: -25px;
}
.bottom-area-svgs .dashed1 {
	-webkit-animation-delay: 1s;
	animation-delay: 0.4s;
}
.bottom-area-svgs .dashed1 {
	-webkit-animation: dash_fill_1500 0.9s linear;
	animation: dash_fill_1500 0.9s linear;
}

.bottom-area-svgs .dashed1 {
	-webkit-animation: dash_fill_1500 2s linear;
	animation: dash_fill_1500 2s linear;
}
.bottom-area-svgs .dashed1 {
	stroke-dashoffset: 3000;
}
@-webkit-keyframes dash_fill_1500 {
	from {
		stroke-dashoffset: 3000;
	}
	to {
		stroke-dashoffset: 0;
	}
}
@keyframes dash_fill_1500 {
	from {
		stroke-dashoffset: 3000;
	}
	to {
		stroke-dashoffset: 0;
	}
}
.works-items-wrappers {
	padding-top: 45px;
}
/* how it works end */

/* Data Those Area Start */
#data-those {
	background-image: url('../img/home5/data-original.png');
	background-size: cover;
	background-repeat: no-repeat;
}
.data-those-title {
	padding-bottom: 40px;
}
.data-those-inner-text {
	position: relative;
}
.data-those-inner-box {
	position: relative;
	padding-left: 70px;
	margin-bottom: 30px;
}
.data-those-inner-text .data-those-inner-box:last-child {
	margin-bottom: 0;
}
.data-those-inner-box h3 a:hover {
	color: var(--main-theme-color) !important;
}
.icon-font-box {
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	border-radius: 50%;
	border: 2px solid #2da4ee;
}
.icon-font-box i {
	color: #fff;
}
/* Data Those Area End */

/* Testimonials Area Start */
.test5-wrappers {
	padding-top: 40px;
}
.home5-test-items {
	text-align: center;
}
.test-item-tops {
	padding: 70px 50px 180px 50px;
	position: relative;
	z-index: 1;
	background-image: url('../img/svg/svg3.svg');
	background-repeat: no-repeat;
	display: block;
	margin-bottom: 2.5rem;
	background-size: 100%;
	margin: 0px 8px;
	background-position: center;
}
.tops-box-icon i {
	font-size: 70px;
	color: #fff;
	opacity: 0.3;
}
.test-item-tops p {
	padding-top: 30px;
	color: #fff;
}
.test-item-bottoms {
	display: flex;
	justify-content: center;
	align-items: center;
}
.test5-img {
	width: 85px;
	height: 85px;
	border-radius: 50%;
	overflow: hidden;
}
.test5-img img {
	width: 100%;
}
.test5-author-info {
	text-align: left;
	padding-left: 25px;
}
#home5-testimonials .owl-theme .owl-nav [class*='owl-']:hover {
	background: transparent;
	color: var(--main-theme-color);
}
/* Testimonials Area End */
/* --------------------------
             Faqs Page
-----------------------------*/

.faqs-title-page {
	padding: 30px;
	margin-bottom: 50px;
}

#faqs .list-group .list-group-item {
	border: none;
	font-weight: 600;
	background-color: var(--dark-bg-color);
	padding: 19px;
}

#faqs .list-group .list-group-item.active,
#faqs .list-group .list-group-item-action:focus,
.list-group-item-action:hover {
	color: var(--main-theme-color);
}

#accordion0 .card-header,
#accordion1 .card-header,
#accordion2 .card-header,
#accordion3 .card-header,
#accordion4 .card-header,
#accordion5 .card-header {
	padding: 25px 20px;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	background: #fff;
	border: none;
}

#accordion0 .card,
#accordion1 .card,
#accordion2 .card,
#accordion3 .card,
#accordion4 .card,
#accordion5 .card {
	border: 0;
	border-radius: 0;
}
.navbar-area.is-sticky {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999999;
	-webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
	box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
	background-color: #ffffff !important;
	-webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	padding-top: 5px;
	padding-bottom: 5px;
}

.navbar-area.navbar-color-white.is-sticky {
	background-color: #301d44 !important;
}
.banner-three a svg {
	font-size: 45px;
	margin-right: 15px;
}
/* --------------------------
  Shop Page
----------------------------*/

.shop-img {
	background: #e0e0e0;
	padding: 20px;
}

.shop-img img {
	width: 100%;
}

.shop-info {
	padding: 20px;
}

.shop-info h3 {
	font-size: 20px;
	margin: 0;
}

.shop-info p {
	padding-top: 10px;
	margin: 0;
	font-size: 18px;
}

.shop-info a {
	margin-top: 10px;
}

.btn-shop {
	font-size: 14px;
	padding: 17px 14px;
	line-height: 0px;
}

.btn-shop:hover {
	background-position: 100% 0;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	color: #fff;
}

#shop-area .row {
	margin-bottom: 40px;
}

#shop-area .row:last-child {
	margin: 0;
}
.shop-item {
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
	margin-bottom: 30px;
}
.shop-item:hover {
	transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
}
.left-side-title {
	padding-bottom: 0px;
}
#about .left-side-title {
	padding-bottom: 0;
}
/*--pagination--*/

.pagination > li:first-child > a,
.pagination > li:first-child > span {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
	color: none;
	cursor: not-allowed;
	background-color: none;
	border-color: none;
}

.pagination > li > a,
.pagination > li > span {
	font-size: 16px;
	font-weight: 700;
	padding: 5px 15px;
	margin-left: 15px;
	background: none;
	color: #4f535b;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	z-index: 3;
	color: #fff;
	cursor: default;
	background: var(--main-theme-color);
	border-radius: 50%;
}

.blog-pagination {
	margin: 70px auto 0 auto;
}

.dropdown-item.active,
.dropdown-item:active {
	background: #4abbe5;
	color: #fff !important;
	margin: 5px 0;
}

/* --------------------------
  Shop-Details Page
-----------------------------*/

.shop-details-img {
	background: #e0e0e0;
}

#shop-details .owl-theme .owl-dots .owl-dot span {
	height: 20px;
	width: 20px;
	background: #5b6fe680;
}

#shop-details .owl-theme .owl-dots .owl-dot.active span,
#shop-details .owl-theme .owl-dots .owl-dot:hover span {
	background: var(--main-theme-color);
}

.shop-details-img img {
	width: 100%;
}

.customer-star {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
}

.customer-star ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.customer-star ul li {
	padding-right: 10px;
	color: var(--main-theme-color);
}

.customer-star h6 {
	color: var(--main-theme-color);
	font-weight: 600;
	margin: 0;
	font-size: 16px;
}

.shop-details-info h5 {
	font-size: 22px;
	font-weight: 700;
	color: var(--main-theme-color);
	padding-top: 15px;
}

.shop-details-info p {
	padding-top: 10px;
}

.quenty-btn-area {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

#number-box .form-control {
	width: 115px;
	height: 40px;
	border-radius: 30px;
}

#number-box .form-control:focus {
	border: 1px solid var(--main-theme-color);
}

.add-btn {
	padding-left: 20px;
}

.quenty-btn-area {
	padding-top: 20px;
}

.btn-add {
	font-size: 16px;
	font-weight: 600;
}

.shop-category {
	padding-top: 30px;
}

.shop-category ul li {
	display: inline-block;
	color: var(--paragraph-color);
}

.items-product {
	font-weight: 700;
}

.product-tabs-header .nav-tabs {
	border-bottom: none;
}

.product-tabs-header .nav-tabs li {
	font-size: 20px;
	font-weight: 600;
	padding-right: 30px;
}

.product-tabs-header .nav-tabs li .active {
	padding-bottom: 10px;
	border-bottom: 2px solid var(--main-theme-color);
	color: var(--main-theme-color);
}

.product-details-tabs-review {
	margin-top: 40px;
}

#description h3,
#review h3 {
	padding-bottom: 10px;
}

.product-details-tabs-review .tab-content {
	padding: 20px;
}

/* --------------------------
 Cart Page
-----------------------------*/

.table-desc {
	border: 1px solid var(--main-theme-color);
	margin-bottom: 60px;
	margin-top: 2px;
}

.table-desc .cart-page table {
	width: 100%;
}

.table-responsive table thead {
	background: var(--main-theme-color);
}

.cart-page table thead tr:last-child th,
.table-desc table tbody tr td:last-child {
	border-right: 0;
}

.table-desc .cart-page table thead tr th {
	border-bottom: 3px solid var(--main-theme-color);
	border-right: 1px solid var(--main-theme-color);
	font-weight: 600;
	text-transform: capitalize;
	padding: 10px;
	text-align: center;
	color: #fff;
}

.tabl-desc .cart-page table tbody tr td.product-remove {
	min-width: 100px;
}

.table-desc .cart-page table tbody tr td {
	border-bottom: 1px solid var(--main-theme-color);
	border-right: 1px solid var(--main-theme-color);
	text-align: center;
	padding: 10px;
}

.table-responsive table tbody tr td {
	border-right: 1px solid #f0f0f0;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 14px;
	text-align: center;
	min-width: 150px;
}

.table-desc .cart-page table tbody tr td.product-remove a {
	font-size: 20px;
	color: #333;
}

.table-desc .cart-page table tbody tr td.product-remove a:hover {
	color: var(--main-theme-color);
}

.table-desc .cart-page table tbody tr td.product-thumb {
	max-width: 180px;
}

.table-desc .cart-page table tbody tr td.product-name {
	min-width: 180px;
}

.table-desc .cart-page table tbody tr td.product-price {
	min-width: 130px;
	color: #333;
	font-size: 16px;
	font-weight: 500;
}

.table-desc .cart-page table tbody tr td.product-quantity {
	min-width: 180px;
}

.table-desc .cart-page table tbody tr td.product-quantity label {
	font-weight: 600;
	margin-right: 5px;
}

.table-desc .cart-page table tbody tr td.product-quantity input {
	width: 60px;
	height: 40px;
	padding: 0 5px 0 10px;
	background: none;
	border: 1px solid #f0f0f0;
}

.cart-page table thead tr:last-child th,
.table-desc table tbody tr td:last-child {
	border-right: 0;
}

.cart-submit {
	text-align: right;
	padding: 12px;
}

.table-desc .cart-page table tbody tr td.product-remove a:hover {
	color: var(--main-theme-color);
}

.coupon-code {
	border: 1px solid #f0f0f0;
}

.coupon-code h3 {
	color: #ffffff;
	line-height: 36px;
	padding: 5px 15px;
	background: var(--main-theme-color);
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
}

.coupon-inner {
	padding: 10px 20px 25px;
}

.coupon-inner p {
	font-size: 14px;
	margin-bottom: 20px;
}

.coupon-inner input {
	border: 1px solid var(--main-theme-color);
	height: 42px;
	background: none;
	padding: 0 20px;
	margin-right: 20px;
	font-size: 14px;
}

.coupon-inner .btn-shop {
	border-radius: 0;
	padding: 22px 20px;
}

.cart-subtotal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.cart-subtotal p {
	font-weight: 600;
	font-size: 14px;
}

.cart-subtotal p.cart-amount {
	font-size: 18px;
	font-weight: 500;
}

.calculate {
	display: block;
	text-align: right;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 20px;
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 10px;
	border-radius: 3px;
}

/* --------------------------
Checkout Page
-----------------------------*/

.bg-white {
	background-color: #fff;
}

.checkout-area-bg {
	padding: 30px;
}

.check-heading {
	border-bottom: 2px solid var(--main-theme-color);
}

.check-heading h3 {
	font-weight: 700;
	padding-bottom: 10px;
}

.check-out-form {
	padding-top: 30px;
}

#checkout-area .form-group {
	margin-bottom: 20px;
}

#checkout-area .form-group label {
	font-size: 16px;
	font-weight: 600;
}

#checkout-area .form-control {
	border: 1px solid var(--main-theme-color);
	background: #fff;
	font-size: 14px;
	border-radius: 0;
}

.order_review {
	background-color: #f7f8fb;
	padding: 30px;
}

.order_table thead th {
	border-bottom-width: 1px;
	font-weight: 600;
	color: #fff;
}

.order_table tbody td {
	border: 0;
}

.order_table tfoot th {
	font-weight: 600;
}

.product-subtotal {
	font-weight: 600;
}

.payment_method {
	margin-bottom: 30px;
	display: inline-block;
	width: 100%;
}

.payment_option .custom-radio {
	margin-bottom: 10px;
}

.custom-radio .form-check-input,
.custome-checkbox .form-check-input {
	display: none;
}

.payment_option .custom-radio .form-check-label {
	color: #292b2c;
	font-weight: 600;
}

.custom-radio .form-check-label,
.custom-checkbox .form-check-label {
	position: relative;
	cursor: pointer;
}

.custom-radio input[type='radio']:checked + .form-check-label::before {
	border-color: var(--main-theme-color);
}

.custom-radio .form-check-label::before {
	content: '';
	border: 1px solid #908f8f;
	height: 16px;
	width: 16px;
	display: inline-block;
	border-radius: 100%;
	vertical-align: middle;
	margin-right: 8px;
}

.custom-radio input[type='radio']:checked + .form-check-label::after {
	opacity: 1;
}

.custom-radio input[type='radio'] + .form-check-label::after {
	content: '';
	background-color: var(--main-theme-color);
	height: 10px;
	width: 10px;
	border-radius: 100%;
	position: absolute;
	top: 8px;
	left: 3px;
	opacity: 0;
}

.payment-text {
	display: none;
	margin-top: 5px;
	margin-bottom: 0;
}

.payment_option .custom-radio:first-child .payment-text {
	display: block;
}

.payment_option .custom-radio .form-check-label {
	color: #292b2c;
	font-weight: 600;
}

.order_review {
	margin-bottom: 40px;
}

.payment_method {
	padding-top: 30px;
}

.order_table {
	padding-top: 30px;
}

/* --------------------------
Blog Page
-----------------------------*/

.blog-widget {
	padding: 20px 20px;
	margin-top: 50px;
}

.blog-wrapper-blog-item .blog-widget:nth-child(1) {
	margin: 0;
}

.left-blog-page {
	padding: 20px;
	margin-bottom: 30px;
}

.blog-widget-img {
	overflow: hidden;
}
.blog-widget-paragraph {
	padding: 20px 0;
}

.blog-widget-img img {
	width: 100%;
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
}

.blog-widget-img img:hover {
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
}

.blog-widget-text {
	padding-top: 20px;
}

.blog-widget-author {
	border-top: 1px solid #cccccc42;
	border-bottom: 1px solid #cccccc42;
	padding: 5px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.blog-author-view ul li {
	display: inline-block;
	padding-right: 30px;
	color: var(--paragraph-color);
	font-size: 14px;
}
.blog-author-view ul li a i {
	padding-right: 6px;
}
.blog-author-view ul li:nth-child(1) a {
	color: var(--main-theme-color);
}

.blog-author-date ul li {
	color: var(--paragraph-color);
	font-size: 14px;
}

.blog-widget-paragraph {
	padding-top: 20px;
}

.blog-widget-button {
	padding-top: 30px;
}

.blog-widget-heading h2 {
	padding-bottom: 10px;
}

.right-side {
	padding-left: 0px;
}

.blog-search-option {
	display: block;
	background: #fff;
	border: 1px solid var(--main-theme-color);
	border-radius: 3px;
	position: relative;
}

.blog-search-option input {
	padding: 5px 15px;
	width: 87%;
	border: none;
	height: 56px;
	background: transparent;
}

.blog-search-option button {
	background: transparent;
	border: none;
}

.blog-search-option button i {
	line-height: 16px;
	font-size: 18px;
	position: absolute;
	right: 15px;
	top: 22px;
}

.left-blog {
	overflow: hidden;
	padding-bottom: 20px;
}

.left-blog h4 {
	position: relative;
	color: #444;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 5px;
	padding: 15px 0px;
	text-transform: capitalize;
}

.left-blog h4::after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 50px;
	height: 2px;
	background: var(--main-theme-color);
}

.left-blog li {
	display: block;
}

.left-blog ul li a {
	display: inline-block;
	padding: 7px 0px 7px 0px;
	text-transform: capitalize;
	position: relative;
}

.left-blog ul li a:hover {
	color: var(--main-theme-color);
}

.left-blog ul li span {
	float: right;
	border: 1px solid var(--main-theme-color);
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 50px;
	color: var(--main-theme-color);
	line-height: 30px;
}

.recent-single-post {
	display: block;
	overflow: hidden;
	padding: 15px 0px;
}

.post-img {
	display: inline-block;
	float: left;
	padding-right: 10px;
}

.recent-single-post img {
	width: 100%;
	max-width: 80px;
	height: 80px;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 2px;
}

.pst-content {
	padding-left: 100px;
}

.left-head-blog .pst-content p {
	margin-bottom: 5px;
}

.pst-content .date-type {
	font-size: 14px;
}

.pst-content p a {
	color: #444;
	font-size: 15px;
}

.pst-content p a:hover {
	color: var(--main-theme-color);
}

.blog-tags {
	padding: 1px 0;
}

.left-blog {
	overflow: hidden;
	padding-bottom: 20px;
}

.left-side-tags h4 {
	margin-bottom: 15px;
}

.popular-tag.left-side-tags.left-blog ul {
	padding: 0px;
}

.left-tags .left-side-tags ul li {
	border-bottom: 0;
	display: inline-block;
	margin: 5px 3px;
}

.left-tags .left-side-tags ul li a {
	padding: 5px 10px;
	width: auto;
	background: #e8e8e8;
	border-radius: 3px;
	font-weight: 400;
}

.left-tags .left-side-tags ul li a:hover {
	color: #fff;
	background: var(--main-theme-color);
}
.pagination-area ul {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
}
.pagination-area ul li {
	background: #242f51;
	margin-right: 10px;
	width: 31px;
	height: 31px;
	line-height: 34px;
	text-align: center;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
	transition: 0.4s all ease-in-out;
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-ms-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
	font-size: 15px;
}
.pagination-area ul li:hover {
	background-color: var(--main-theme-color);
	color: #fff;
}
.pagination-area ul li.active {
	background-color: var(--main-theme-color);
	color: #fff;
}
.main-amount {
	font-weight: 700 !important;
}

/* --------------------------
Blog-Details Page
-----------------------------*/

.blog-widget-paragraph h3 {
	font-weight: 600;
	padding-top: 20px;
	padding-bottom: 10px;
}

.blog-main-details {
	padding: 25px 25px;
}

.blog-main-details hr {
	margin: 0;
	margin-top: 20px;
}

.blog-share-area {
	padding: 20px 0 0 0;
}

.blog-share-area ul li {
	display: inline-block;
	padding-right: 10px;
	font-weight: 500;
}

.social-icon {
	background: #cccccc;
	height: 30px;
	width: 30px;
	display: inline-block;
	line-height: 36px;
	text-align: center;
	border-radius: 50%;
	font-size: 16px;
	transition: 0.4s all ease;
	-webkit-transition: 0.4s all ease;
	-moz-transition: 0.4s all ease;
	-ms-transition: 0.4s all ease;
	-o-transition: 0.4s all ease;
}

.social-icon:hover {
	background: var(--main-theme-color);
	color: #fff;
}

.media .media-img img {
	width: 65px;
	height: 65px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.media-author {
	padding: 20px;
	margin-top: 30px;
}

.media-img {
	padding-right: 20px;
}

.media-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.media-name h4 {
	margin: 0;
}

.media-name p {
	padding-top: 5px;
	color: var(--main-theme-color);
}

.post-share a {
	margin-right: 10px;
}

.media-paragraph {
	padding-top: 10px;
}

.media-public ul li {
	padding-bottom: 20px;
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 30px;
}

.media-public ul li:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.media-public .post-share a {
	font-size: 14px;
}

.media-public .post-share .replay {
	color: var(--main-theme-color);
}
.comment-area-post {
	margin-top: 30px;
	padding: 20px;
}
.comment-area-heading h3 {
	font-weight: 700;
	padding-bottom: 20px;
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 30px;
}

.leave-form .form-control {
	border: 1px solid var(--main-theme-color);
}

.btn:hover {
	color: #fff;
	text-decoration: none;
}

/* --------------------------
Contact Page
-----------------------------*/

#contact {
	padding-bottom: 100px;
}

.map-content {
	padding-bottom: 100px;
}

.contact-content p {
	padding-top: 10px;
}

.contact-info {
	padding-top: 30px;
}

.contact-info li {
	margin-bottom: 20px;
}

.contact-info li .icon-area {
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 50px;
	font-size: 24px;
	margin-right: 20px;
	-webkit-box-shadow: 0px 0px 13px -3px #5969c3;
	box-shadow: 0px 0px 13px -3px #5969c3;
}

.contact-info li .icon-area i {
	color: var(--main-theme-color);
}

.contact-info li .contact-info-here h5 {
	font-size: 16px;
	line-height: 30px;
	margin: 0;
}

.contact-info li .contact-info-here h5 a {
	font-size: 16px;
}

.contact-form-area {
	padding: 35px;
}

.contact-form-area .form-control {
	border-radius: 0;
	border: 1px solid var(--main-theme-color);
	font-size: 14px;
	margin-bottom: 30px;
	background: #fff;
}

.map-content iframe {
	width: 100%;
	height: 500px;
	border: none;
}

/* --------------------------
Privacy-policy Page
-----------------------------*/

.privacy-left-arae h3 {
	font-size: 22px;
	font-weight: 600;
	margin: 0;
	padding-top: 30px;
}

.privacy-left-arae h3:first-child {
	padding-top: 0;
}

.privacy-left-arae p {
	margin: 0;
	padding-top: 16px;
}

.privacy-left-arae img {
	padding-top: 30px;
}

.privacy-left-arae ul {
	padding-top: 20px;
}

.privacy-left-arae ul li {
	margin-bottom: 20px;
}

.privacy-left-arae ul li:last-child {
	margin-bottom: 0px;
}

.privacy-left-arae ul li i {
	color: var(--main-theme-color);
	font-size: 18px;
	padding-right: 10px;
}

/* --------------------------
 Login Page
-----------------------------*/

.login-form-arae {
	background-color: #ffffff;
	border-radius: 4px;
	-webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
	box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
	padding: 40px;
	max-width: 570px;
	margin: auto;
}

.social-login-area {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 30px 0;
}

.social-login-area button {
	height: 40px;
	border-radius: 33px;
	border: none;
	font-size: 16px;
	font-weight: 600;
	padding: 0px 22px;
	color: #fff;
	transition: 0.4s all ease;
	-webkit-transition: 0.4s all ease;
	-moz-transition: 0.4s all ease;
	-ms-transition: 0.4s all ease;
	-o-transition: 0.4s all ease;
	cursor: pointer;
}

.social-login-area button i {
	padding-right: 5px;
}

.social-login-area button:nth-child(1) {
	background-color: #ff5733;
}

.social-login-area button:hover:nth-child(1),
.social-login-area button:hover:nth-child(2),
.social-login-area button:hover:nth-child(3) {
	background-color: #000;
}

.social-login-area button:nth-child(2) {
	background-color: #3955bc;
}

.social-login-area button:nth-child(3) {
	background-color: #1dd3f8;
}

.login-form-arae form .form-control {
	border-radius: 0;
	background: #fff;
	border: 1px solid var(--main-theme-color);
	font-size: 14px;
	margin-bottom: 30px;
}

.login-button {
	text-align: center;
	padding: 30px 0;
}

.login-desc p {
	color: var(--heading-color);
}

.login-desc p a {
	color: var(--main-theme-color);
}

.pasword-remamber a {
	color: var(--main-theme-color);
}

/* --------------------------
Product-Details Page
-----------------------------*/

.project-desc h3 {
	padding-top: 30px;
}

.project-desc p {
	padding-top: 10px;
	margin: 0;
}

.project-desc blockquote {
	padding: 20px 35px;
	position: relative;
	margin: 25px 0px;
}

.project-desc blockquote:after {
	content: '';
	position: absolute;
	height: 100%;
	width: 13px;
	background: var(--main-theme-color);
	left: 0;
	top: 0;
}

.project-details-bottom {
	padding-top: 30px;
}

.pro-deta-list {
	padding-top: 30px;
}

.pro-deta-list ul li {
	margin-bottom: 15px;
	transition: 0.4s all ease;
	-webkit-transition: 0.4s all ease;
	-moz-transition: 0.4s all ease;
	-ms-transition: 0.4s all ease;
	-o-transition: 0.4s all ease;
	display: flex;
}

.pro-deta-list ul li:hover {
	transform: translateX(10px);
	-webkit-transform: translateX(10px);
	-moz-transform: translateX(10px);
	-ms-transform: translateX(10px);
	-o-transform: translateX(10px);
}

.pro-deta-list ul li i {
	color: var(--main-theme-color);
	font-size: 18px;
	padding-right: 5px;
}

.ps-informations h3 {
	padding: 14px 20px;
	background: var(--main-theme-color);
	border-radius: 0;
	font-size: 22px;
	color: #fff;
}

.ps-informations ul {
	margin: 0;
	list-style: none;
	text-align: left;
	background: #fff;
	-webkit-box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
	box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
	padding: 30px 25px 40px;
}

.ps-informations ul li {
	position: relative;
	padding-bottom: 20px;
}

.ps-informations ul li:last-child {
	padding: 0;
}

.ps-informations ul li span {
	width: 150px;
	display: inline-block;
	color: #333;
	position: relative;
}

.ps-informations ul li span i {
	color: #f26723;
	transition: all 0.5s ease;
	opacity: 0.5;
	font-size: 20px;
	padding-right: 10px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
}

.company-info {
	margin-top: 50px;
	background: #fff;
	-webkit-box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
	box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
	padding: 30px 25px 40px;
}

.company-info p {
	padding-top: 10px;
}

.company-info a {
	margin-top: 10px;
}

.contact-widget {
	margin-top: 30px;
}

.contact-widget h3 {
	padding: 14px 20px;
	background: var(--main-theme-color);
	border-radius: 0;
	font-size: 22px;
	color: #fff;
}

.contact-widget ul {
	margin: 0;
	list-style: none;
	text-align: left;
	background: #fff;
	-webkit-box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
	box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
	padding: 30px 25px 40px;
}

.contact-widget ul li {
	margin-bottom: 15px;
	display: flex;
}

.contact-widget ul li:last-child {
	margin: 0;
}

.contact-widget ul li i {
	font-size: 25px;
	color: var(--main-theme-color);
	padding-right: 10px;
}

/* --------------------------
Product Page
-----------------------------*/

.project-grid-item {
	position: relative;
	-webkit-transition: 0.4s all ease;
	-o-transition: 0.4s all ease;
	transition: 0.4s all ease;
}

.project-grid-item:hover {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

.project-grid-img {
	position: relative;
	margin-bottom: 60px;
}

.project-grid-img img {
	border-radius: 10px;
	width: 100%;
}

.project-grid-content {
	position: absolute;
	left: 50%;
	bottom: -15px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	transition: all 0.6s ease;
	opacity: 1;
	z-index: 99;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	text-align: center;
}

.project-grid-content h5 a {
	background: var(--main-theme-color);
	padding: 10px 12px;
	border-radius: 35px;
	color: #fff;
	transition: 0.4s all ease;
	-webkit-transition: 0.4s all ease;
	-moz-transition: 0.4s all ease;
	-ms-transition: 0.4s all ease;
	-o-transition: 0.4s all ease;
}

.project-grid-content h5 a:hover {
	color: #000;
}

/* --------------------------
Service Page
-----------------------------*/

.service-dec-img {
	text-align: center;
}

.service-dec-left-text p {
	padding-top: 10px;
}

.service-dec-left-text a {
	margin-top: 15px;
	margin-right: 20px;
}

.counter-area {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.counter-icon i {
	font-size: 45px;
	color: var(--main-theme-color);
	padding-right: 10px;
}

.counter-body h2 {
	margin: 0;
}

.counter-body h4 {
	padding-top: 5px;
}

.counter-body p {
	padding-top: 5px;
}

/* --------------------------
Service-Details Page
-----------------------------*/

.service-img-text p {
	padding-top: 15px;
}

.service-img-text ul li {
	padding-top: 10px;
	font-size: 16px;
	display: flex;
}

.service-img-text ul li i {
	font-size: 15px;
	padding-right: 10px;
	color: var(--main-theme-color);
}

.service-details-img {
	text-align: center;
}

/* --------------------------
  About Page
-----------------------------*/

.about-img ul li {
	margin-bottom: 13px;
	color: var(--heading-color);
}

.about-img ul li i {
	font-size: 24px;
	color: var(--main-theme-color);
	padding-right: 10px;
}

/*----------------------------
preloder
--------------------------------*/

#preloader {
	background-color: #000000;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99999999999;
}

#status {
	width: 66px;
	height: 66px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -33px;
	margin-left: -33px;
}

.lds-facebook {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 6px;
	background: var(--main-theme-color);
	-webkit-animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
	left: 5px;
	-webkit-animation-delay: -0.24s;
	animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
	left: 20px;
	-webkit-animation-delay: -0.12s;
	animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
	left: 35px;
	-webkit-animation-delay: 0;
	animation-delay: 0;
}

.lds-facebook div:nth-child(4) {
	left: 50px;
	-webkit-animation-delay: -0.24s;
	animation-delay: -0.24s;
}

.lds-facebook div:nth-child(5) {
	left: 65px;
	-webkit-animation-delay: -0.12s;
	animation-delay: -0.12s;
}

@-webkit-keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}

	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}

	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

/*----------------------------
 Back to top Button
--------------------------------*/

.go-top.active {
	top: 98%;
	-webkit-transform: translateY(-98%);
	-ms-transform: translateY(-98%);
	transform: translateY(-98%);
	opacity: 1;
	visibility: visible;
}

.go-top {
	position: fixed;
	cursor: pointer;
	top: 0;
	right: 15px;
	color: #ffffff;
	background-color: var(--main-theme-color);
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 40px;
	line-height: 40px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.9s;
	-o-transition: 0.9s;
	transition: 0.9s;
	border-radius: 50%;
}

.go-top::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: #393953;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
	border-radius: 50%;
}

.go-top i {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.go-top i:last-child {
	opacity: 0;
	visibility: hidden;
	top: 60%;
}

.go-top:hover,
.go-top:focus {
	color: #ffffff;
}

.go-top:hover::before,
.go-top:focus::before {
	opacity: 1;
	visibility: visible;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
	opacity: 0;
	top: 0;
	visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
	opacity: 1;
	visibility: visible;
	top: 50%;
}

#home5-testimonials .slick-next {
	right: -25px;
	bottom: -50px;
	left: 52%;
	top: auto;
}
#home5-testimonials .slick-prev {
	left: -25px;
	bottom: -50px;
	left: 48%;
	top: auto;
}
#recent-work-three .recent-wrapper .area-flexed {
	display: flex !important;
}

#recent-work-three .slick-prev {
	left: -25px;
	bottom: -50px;
	left: 48%;
	top: auto;
}
#recent-work-three .slick-next {
	right: -25px;
	bottom: -50px;
	left: 52%;
	top: auto;
}
